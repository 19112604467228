import axios from 'axios'
import store from '../store/index'
import { showFullScreenLoading, tryHideFullScreenLoading } from  './serviceHelp'
axios.defaults.baseURL = 'http://1.116.134.48:7001/';
// axios.defaults.baseURL = 'http://localhost:7001/';
export default function setAxios() {
  const token = localStorage.getItem('getToken')
  //请求拦截
  axios.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${token}`
      if (token) {
        // 将token设置在headers ['Authorization'] 上面
        config.headers['Authorization'] = `Bearer ${token}`
      }
      // if (config.showLoading){
      //   showFullScreenLoading()
      // }
      return config
    }
  )

  // axios.interceptors.response.use(  response => {
  //   if (response.config.showLoading){
  //     tryHideFullScreenLoading()
  //   }
  // })
}